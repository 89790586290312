<template>
  <!-- Desc: Used to show product and category conditionally  -->
  <div class="u-display-flex">
    <div
      v-if="allowEmptyAsin && !asin"
      class="u-text-overflow-ellipsis u-color-grey-base"
    >
      <span class="product-asin-title-hover">NA</span>
    </div>
    <div
      v-if="productTitle || (asin && !categoryTitle)"
      class="u-display-flex u-spacing-pb-xs u-width-100 u-flex-direction-column"
    >
      <div
        v-if="productTitle"
        class="u-text-overflow-ellipsis u-color-grey-base"
      >
        <span class="u-font-weight-600">Product:</span>
        <span
          class="product-asin-title-hover"
          :title="productTitle"
          @click="navigateToSDP(asin)"
          >{{ productTitle }}</span
        >
      </div>
      <rb-asin
        v-if="asin"
        :asin="asin"
        :product-page-url="productPageUrl"
        :tippy-text="pdpTooltip"
      />
    </div>
    <div
      v-if="categoryTitle"
      class="u-text-overflow-ellipsis u-cursor-pointer u-color-grey-base"
    >
      <span class="u-font-weight-600">Category:</span>
      <span :title="categoryTitle">{{ categoryTitle }}</span>
    </div>
  </div>
</template>

<script>
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';

export default {
  props: {
    productTitle: {
      type: String,
      default: null
    },
    categoryTitle: {
      type: String,
      default: null
    },
    asin: {
      type: String,
      default: null
    },
    productPageUrl: {
      type: String,
      default: null
    },
    pdpTooltip: {
      type: String,
      default: 'View on amazon product page.'
    },
    entityId: {
      type: String,
      default: null
    },
    entityType: {
      type: String,
      default: null
    },
    allowEmptyAsin: {
      // to show NA if empty
      type: Boolean,
      default: false
    }
  },
  created() {
    if (this.entityType === 'category') {
      this.productTitle = null;
      this.asin = null;
    } else if (this.entityType === 'asin') {
      this.categoryTitle = null;
    }
  },
  methods: {
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    }
  }
};
</script>

<style lang="css">
.product-asin-title-hover:hover {
  text-decoration: underline;
}
</style>
